import React from 'react';
import { useLocation } from 'react-router-dom';
import Game from './Game';

const WrappedGame = props => {
    const location = useLocation();
  
    return <Game location={location} {...props} />
  }

  export default WrappedGame;