import React, { Component } from 'react';

class InfoBox extends Component {

    render() {
        return (
            
            <div>
                <div className="overlay"></div>
                <div className="infobox-parent">
                    <div className="infobox">
                    {this.props.text}
                    <br/>
                    <button onClick={this.props.onClose}>{this.props.close}</button>
                    </div>
                </div>
            </div>
            
        );
    }
}

export default InfoBox;