import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
  } from 'react-router-dom';
import GamePage from '../Game/WrappedGame';
import StartPage from '../Start/Start';
import * as ROUTES from '../../constants/routes';

const App = () => (
    <BrowserRouter>
      <Routes>
        <Route exact path={ROUTES.START} element={<StartPage />} />
        <Route path={ROUTES.GAME} element={<GamePage />} />
      </Routes>
    </BrowserRouter>
);
export default App;